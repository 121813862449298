<template>
  <div>
    <div class="conatiner">
      <el-tabs v-model="activeName" @tab-click="tabsClick">
        <el-tab-pane label="客户画像" name="portrait">
          <div class="labels">
            <div class="label-text">客户标签</div>
            <template v-for="(item, index) in labelList">
              <el-button :type="item.tagType==='0'?'danger':'primary'" class="label-item" :key="index">
                {{item.tagDescription}}
                <i class="el-icon-close label-delete" v-if="item.tagType==='1'" @click="delLabel(item)"></i>
              </el-button>
            </template>
            <el-button type="primary" class="label-item" @click="dialog_show"><i class="el-icon-plus"></i>添加标签</el-button>
          </div>
          <div class="chart">
            <div class="label-text">拜访分析</div>
            <div class="chart-bar">
              <Highcharts id="chartBar" :options="options"></Highcharts>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="业务动态" name="business">
          <div class="business" v-infinite-scroll="loadMore" infinite-scroll-disabled="scrollDisabled">
            <template v-for="(item, index) in businessList">
              <div class="business-item" :key="index">
                <div class="item-left">
                  <i class="el-icon-time"></i>
                  <div class="item-line"></div>
                </div>
                <div class="item-right">
                  <div class="item-time">{{item.createDateAll}}</div>
                  <div class="item-title">
                    <span>{{item.businessType}}</span>
                    <span>{{item.createName}}</span>
                  </div>
                  <div class="item-content">完成工作：{{item.businessType}}</div>
                </div>
              </div>
            </template>
            <div v-if="page.total===0">暂无数据</div>
            <div v-else-if="loading">加载中...</div>
            <div v-else-if="noMore">没有更多了</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="dialog-footer">
      <el-button @click="close" type="danger" size="small" icon="el-icon-close">关闭</el-button>
    </div>
    <el-dialog :title="modalConfig.title" :visible.sync="modalConfig.visible" :width="modalConfig.width" append-to-body>
      <el-form class="dialog-body">
        <el-form-item label="标签名称" label-width="80px" required>
          <el-input v-model="modalConfig.labelName" autocomplete="off" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialog_btnSubmit" size="small" icon="el-icon-check" >提交</el-button>
        <el-button type="danger"  @click="modalConfig.visible=false" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { Chart } from 'highcharts-vue';
import request from '../../../../../../utils/request';

export default {
  name: 'customer_portrait',
  components: {
    Highcharts: Chart,
  },
  data() {
    return {
      activeName: 'portrait',
      // 标签列表
      labelList: [],
      // 图表配置信息
      options: {},
      // x轴
      categories: [],
      // 图表数据
      chartData: [],
      // 业务动态数据
      businessList: [],
      page: {
        pageNum: 1,
        pageSize: 30,
        total: 0,
      },
      // 业务动态数据加载中
      loading: false,
      // 业务动态数据是否还有更多
      noMore: true,
      // 添加标签弹窗
      modalConfig: {
        title: '添加标签',
        visible: false,
        width: '500px',
        labelName: '',
      },
      formConfig: {},
    };
  },
  created() {
    this.options = this.getOptions();
    this.formConfig = this.$attrs.formConfig;
    this.clientCode = this.formConfig.customerCode || this.formConfig.terminalCode;
    this.getLabelList();
    this.getVisitData();
    this.getBusinessList();
  },
  mounted() {
  },
  computed: {
    scrollDisabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    // 获取标签列表
    getLabelList() {
      const params = {
        clientCode: this.clientCode,
      };
      request.post('/mdm/mdmCustomerTagController/findList', params).then((res) => {
        this.labelList = res.result || [];
      });
    },
    // 删除标签
    delLabel(item) {
      this.$confirm(`是否删除【${item.tagDescription}】该标签？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        request.get('/mdm/mdmCustomerTagController/delete', { id: item.id }).then((res) => {
          this.getLabelList();
        });
      });
    },
    // 获取客户月度拜访统计
    getVisitData() {
      const { clientCode } = this;
      // const clientCode = 'ZD00000056';
      request.get('/sfa/sfaCustomerVisitTotalController/findCustomerVisitTotal', { clientCode }).then((res) => {
        if (res.result && res.result.length > 0) {
          const categories = [];
          const chartData = [];
          res.result.map((v) => {
            categories.push(v.visitDateMonth);
            chartData.push(Number(v.visitNum));
            return v;
          });
          this.categories = categories;
          this.chartData = chartData;
        }
        this.options = this.getOptions();
      });
    },
    // 获取客户业务动态
    getBusinessList() {
      const params = {
        clientCode: this.clientCode,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      request.post('/sfa/sfaCustomerBusinessDetailController/page', params).then((res) => {
        const { result } = res;
        this.page.total = result.count;
        this.businessList = this.businessList.concat(result.data || []);
        this.noMore = this.page.pageNum * this.page.pageSize > this.page.total;
        this.loading = false;
      });
    },
    // 加载更多
    loadMore() {
      if (this.noMore) return false;
      this.loading = true;
      this.page.pageNum += 1;
      this.getBusinessList();
    },
    // 关闭弹框
    close() {
      this.$emit('onClose');
    },
    // 切换tabs
    tabsClick(val, e) {
      this.activeName = val.name;
    },
    // 显示标签弹窗
    dialog_show() {
      this.modalConfig.labelName = '';
      this.modalConfig.visible = true;
    },
    // 点击标签弹窗提交按钮
    dialog_btnSubmit() {
      if (!this.modalConfig.labelName) {
        this.$message.warning('请输入标签名称！');
        return false;
      }
      const params = {
        clientCode: this.clientCode,
        tagDescription: this.modalConfig.labelName,
        tagType: '1',
      };
      request.post('/mdm/mdmCustomerTagController/save', params).then((res) => {
        this.getLabelList();
        this.modalConfig.visible = false;
      });
    },
    // 获取图表配置项
    getOptions() {
      const options = {
        chart: {
          type: 'column',
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top',
        },
        plotOptions: {
          series: {
            pointWidth: 20, // 设置柱子宽度
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span>{point.category}</span>: <b style="color:{point.color}">{point.y}</b><br/>',
        },
        xAxis: {
          lineColor: this.categories && this.categories.length > 0 ? '#409eff' : '#ffffff',
          categories: this.categories,
        },
        yAxis: {
          min: 0,
          lineColor: '#409eff',
          gridLineColor: '#409eff',
          gridLineDashStyle: 'Dash',
          title: {
            text: '',
          },
          labels: {
            overflow: 'justify',
          },
        },
        series: [{
          name: '拜访数量',
          color: '#409eff',
          data: this.chartData,
        }],
      };
      return options;
    },
  },
};
</script>
<style lang="less" scoped>
  .el-icon-plus{
    padding-right: 4px;
  }
  .labels{
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
    .label-item{
      margin: 0 10px 10px 0;
      position: relative;
      .label-delete{
        position: absolute;
        top: -2px;
        right: -2px;
        padding: 2px;
      }
    }
  }
  .chart{
    padding-top: 20px;
    #chartBar{
      width: 600px;
      height: 300px;
    }
  }
  .label-text{
    font-size: 14px;
    padding-bottom: 10px;
  }
  .business{
    width: 100%;
    // height: calc(100vh - 210px);
  }
  .business-item{
    display: flex;
    align-items: stretch;
    .item-left{
      width: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      i{
        font-size: 20px;
        color: #409eff;
      }
      .item-line{
        width: 1px;
        flex: 1;
        background-color: #409eff;
      }
    }
    .item-right{
      padding-left: 20px;
      padding-bottom: 20px;
      flex: 1;
      line-height: 20px;
      .item-title{
        width: 200px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .dialog-body{
    padding: 20px 20px 50px 20px;
  }
</style>
